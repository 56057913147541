import { Pipe, PipeTransform } from '@angular/core';
import { FirebaseService } from './firebase.service';


@Pipe({
  name: 'projectIdToName'
})
export class ProjectIdToNamePipe implements PipeTransform {
  constructor(
    private firebaseService: FirebaseService) { }

  transform(value: string): string {

    for (const project of this.firebaseService.ownedProjects){
      if (project.id == value ){
        return project.name;
      }
    }
    return value;
  }

}
