<app-loading *ngIf="!user"></app-loading>

<div class="card card_center">
    <div class="page-title">
        {{ 'Subscription' | translate}}
    </div>
    <div class="content" *ngIf="user">
        <div class="properties" *ngIf="user.subscription">
            <div class="property">
                <div class="property_label">
                    {{'subscription.status' | translate}}
                </div>

                <ng-template [ngIf]="user.subscription.status !=='active'">

                    <div class="property_value" *ngIf="user.subscription.status == null">
                        {{'subscription.noSubscription' | translate}}
                        <button (click)="startNewSubscription()" mat-raised-button color="primary">
                            {{'subscription.startSubscription' | translate}} </button>
                    </div>

                    <div class="property_value" *ngIf="user.subscription.status === 'trial'">
                        <ng-container *ngIf="user.subscription.currentPeriodEnd < currentTime"> {{ 'subscription.trialEnded' | translate}}</ng-container>
                        <ng-container *ngIf="user.subscription.currentPeriodEnd > currentTime"> {{ 'subscription.trialEnds' | translate}}  <span class="date"> {{user.subscription.currentPeriodEnd | date}} </span> </ng-container>
                       
                       <button
                            (click)="startNewSubscription()" mat-raised-button color="primary">
                            {{'subscription.startSubscription' | translate}} </button>
                    </div>

                    <div class="property_value" *ngIf="user.subscription.status =='past_due'">
                        <mat-icon style="margin-right:8px" color="warn">warning</mat-icon>
                        {{'subscription.pastDueMessage' | translate}}



                    </div>
                </ng-template>

                <ng-template [ngIf]="user.subscription.stripeSubscriptionId">
                    <div class="property_value" *ngIf="user.subscription.status === 'active'">
                        <ng-template [ngIf]="user.subscription.cancel_at_period_end">
                            {{'subscription.willCancelOn' | translate}}
                            <span class="date">{{user.subscription.currentPeriodEnd | date}}</span>
                            <button mat-raised-button (click)="setSubscriptionCancel(false)">
                                {{'subscription.unCancelSubscription' | translate }}</button>
                        </ng-template>
                        <ng-template [ngIf]="!user.subscription.cancel_at_period_end">
                            {{'subscription.willAutoRenewOn' | translate}}
                            <span class="date">{{user.subscription.currentPeriodEnd | date}}</span>


                            <div class="spacer"></div>

                            <button mat-raised-button (click)="setSubscriptionCancel(true)">
                                {{'subscription.cancelSubscription' | translate }}</button>

                        </ng-template>

                    </div>
                </ng-template>

            </div>

            <div class="property" *ngIf="user.subscription.status == 'active'">
                <div class="property_label">
                    {{'subscription.currentCard' | translate}}
                </div>



                <div class="property_value">
                    <ng-container *ngIf="currentPaymentCard">
                        {{'subscription.withCredit' | translate}} ****
                        {{currentPaymentCard.endsWith }}

                        <span class="update-link" (click)="updateSubscpritionPayment()">
                            {{'subscription.updateSubscpritionPayment' | translate }}</span>

                        <ng-container *ngIf="currentPaymentCard.expiresInMonths < 3">
                            <mat-icon color="warn">warning</mat-icon>
                            <span *ngIf="currentPaymentCard.expiresInMonths >= 0">
                                {{'subscription.cardExpireSoonWarning'}}
                            </span>
                            <span *ngIf="currentPaymentCard.expiresInMonths < 0">
                                {{'subscription.cardExpiredWarning'}}
                            </span>

                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!currentPaymentCard">
                        Loading...
                    </ng-container>




                </div>

            </div>




            <div class="property">
                <div class="property_label">
                    {{'Projects' | translate}}
                </div>
                <div class="property_value">
                    <div class="progress-container">
                        <mat-progress-bar [color]="(getProjectsProgress() < 100) ? 'primary' : 'warn'"
                            mode="determinate" [value]="getProjectsProgress()" style="height:12px">
                        </mat-progress-bar>
                    </div>
                    <div class="count">
                        ({{this.getTotalOwnedProjects()}} / {{user.subscription.maxProjects}})
                    </div>
                    <div class="spacer"></div>
                    <button class="buy-button" mat-raised-button
                        *ngIf="user.subscription.stripeSubscriptionId && user.subscription.status==='active'"
                        color="primary" (click)="openBuyProjectsDialog()"><mat-icon> add
                        </mat-icon>{{'subscription.addProject' | translate}}</button>
                </div>
            </div>

            <div class="property">
                <div class="property_label">
                    {{'Views' | translate}}
                </div>
                <div class="property_value">
                    <div class="progress-container">
                        <mat-progress-bar [color]="(getViewsProgress() < 100) ? 'primary' : 'warn'" mode="determinate"
                            [value]="getViewsProgress()" style="height:12px">
                        </mat-progress-bar>
                    </div>
                    <div class="count">
                        ({{usersTotalViews}} / {{user.subscription.viewsCredit}})
                    </div>
                    <div class="spacer"></div>
                    <button class="buy-button" mat-raised-button
                        *ngIf="user.subscription.stripeSubscriptionId && user.subscription.status==='active'"
                        color="primary" (click)="openBuyViewsDialog()"><mat-icon> add
                        </mat-icon>{{'subscription.addViews'
                        | translate}}</button>
                </div>
            </div>


        </div>
    </div>

    <div class="init-subscription-error" *ngIf="null == user.subscription">
        {{'subscription.initError' | translate}} <a routerLink="/tickets">{{'subscription.ticketLink' |
            translate}}</a>
    </div>
</div>


<app-loading class="loading" *ngIf="isLoading"></app-loading>
<div id=" add-views" class="subscription-dialog-wrapper" *ngIf="buyViewsDialogOpen">
    <div class="subscription-dialog-wrapper-card">
        <div class="title">
            {{'subscription.addViews'
            | translate}}
        </div>
        <div class="content">
            {{'subscription.howManyViewsText' | translate}}

            <BR><BR>
            <div style="display: flex; justify-content: center;">
                <input #addViewsCreditInput [(ngModel)]="viewsCreditToAdd" type="number" min="1" step="1" class="item"
                    (input)="onAddViewsCreditChanged()">
                x {{1000 | number }} {{"subscription.viewsCredit" | translate}}
            </div>

            <div class="error" [class]="{on: addViewsCreditInput.value %1 !== 0}">*
                {{'subscription.addViewIntOnlyError'
                | translate}}</div>


            <span class="color-primary">{{viewsCreditToAdd * 1000 | number }}</span>
            {{'subscription.addViewsCountMessage' | translate}}
            <BR><BR>
            {{'subscription.youWillBeBilled' | translate}}<span class="color-primary">€{{viewsCreditToAdd *
                price1kViews
                | number}}</span>

        </div>

        <div class="actions">
            <button mat-raised-button (click)="closeBuyViewsDialog()">{{"subscription.cancel"
                |translate }}</button>
            <button mat-raised-button color="primary" [disabled]="viewsCreditToAdd<=0"
                (click)="createViewsCreditCheckout()">{{"subscription.payNow"
                |translate }}</button>
        </div>
    </div>
</div>


<div id="add-project" class="subscription-dialog-wrapper" *ngIf="buyProjectsDialogOpen">
    <div class="subscription-dialog-wrapper-card">
        <div class="title">
            {{'subscription.addProject'
            | translate}}
        </div>
        <div class="content">
            {{'subscription.howManyProjectsText' | translate}}
            <BR><BR>
            <div style="display: flex; justify-content: center;">
                <input #addProjectsInput [(ngModel)]="projectsToAdd" type="number" min="1" step="1" class="item"
                    (input)="onAddProjectsChanged()">

            </div>

            <div class="error" [class]="{on: addProjectsInput.value %1 !== 0}">*
                {{'subscription.addProjectsIntOnlyError'
                | translate}}</div>

            {{'subscription.addProjectsCountMessage' | translate}}
            <span class="color-primary">{{projectsToAdd | number }}</span>
            <BR><BR>
            {{'subscription.youWillBeBilled' | translate}} <span class="color-primary">€{{projectsToAdd
                * price1Project
                | number}}</span>

        </div>

        <div class="actions">
            <button mat-raised-button (click)="closeBuyProjectsDialog()">{{"subscription.cancel"
                |translate }}</button>
            <button mat-raised-button color="primary" [disabled]="projectsToAdd<=0"
                (click)="createProjectsCheckout()">{{"subscription.payNow"
                |translate }}</button>
        </div>
    </div>
</div>