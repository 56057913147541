import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { environment } from './../environments/environment';
import { ReplaySubject } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  cloudFunctionHost = environment.cloundFunctionsHost;
  firebaseConfig = environment.firebaseConfig;
  providerGoogleSignIn;

  ownedProjects = [];

  userDetails = null
  userLoggedin = new ReplaySubject<any>(1);
  user = null;

  constructor(
    public router: Router,
    protected translate: TranslateService) {
    firebase.initializeApp(this.firebaseConfig);


    this.getCurrentUserStatus();

    this.providerGoogleSignIn = new firebase.auth.GoogleAuthProvider();
    // To apply the default browser preference instead of explicitly setting it.
    firebase.auth().useDeviceLanguage();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user) {
      return true;
    }
    else {

      // deal with redirection before login finishes. this is not a good way to do it.. i just kept this old hack for now.

      this.router.navigate(['/loading'], { queryParams: { previous: state.url } });
    }
  }

  googleSignIn() {
    firebase.auth()
      .signInWithPopup(this.providerGoogleSignIn)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        //var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log("error google sign in: " + errorCode + " / " + errorMessage + " / " + email)
      });
  }

  getCurrentUserStatus() {

    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          if (user.isAnonymous) {
            this.signOutUser();
          } else {
            this.user = user;
            this.getUserData();

          }
        } else {
          
          window.location.href = environment.loginURL + "?originalUrl=" + window.location.href;
        }


      }

    );
  }

  async getUserData() {
    this.userDetails = (await firebase.database().ref('users').child(this.user.uid).once("value")).val();
    this.userDetails.id = this.user.uid;

    await this.getOwnedProjects();
    this.userLoggedin.next()
  }

  createNewUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then(
          () => {
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  signInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  uploadFileStorage(file, path) {
    console.log("uploadFileStorage: " + path);
    var storageRef = firebase.storage().ref();
    var newFileRef = storageRef.child(path);
    console.log("user id: " + firebase.auth().currentUser.uid);
    return newFileRef.put(file);
  }

  signOutUser() {
    firebase.auth().signOut().then(() => {
      //this.router.navigate(['/login']);
      //this.getCurrentUserStatus();
      window.location.href = environment.websiteURL;
    }).catch((error) => {
      // An error happened.
    });;
  }

  sendPasswordResetEmail(emailAddress) {
    firebase.auth().languageCode = this.translate.currentLang;

    return firebase.auth().sendPasswordResetEmail(emailAddress);
  }

  handleResetPassword(actionCode, newPassword) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
      var accountEmail = email;

      // Save the new password.
      firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
        window.location.href = environment.loginURL;
        //this.router.navigate(['/login']);
        // Password reset has been confirmed and new password updated.

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }).catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  getToken() {
    return firebase.auth().currentUser.getIdToken(false);
  }

  async getTotalViewsCountOfUid(uid) {
    const views = Number((await firebase.database().ref("viewsCount/byUser").child(uid).once('value')).val());
    return views;
  }


  // async callCloudFunction(functionName, params?) {

  //   let paramsString = '';
  //   if (params) {
  //     paramsString += '?'
  //     for (let key in params) {
  //       if (paramsString !== '?') {
  //         paramsString += '&';
  //       }

  //       paramsString += String(key) + '=';
  //       paramsString += String(params[key]);
  //     }

  //     console.log(paramsString)
  //   }

  //   const response = await fetch(this.cloudFunctionHost + '/' + functionName + paramsString, //TODO : Just replace with axios which deals better with different response type.. see webapp.. but for some reason i fail to install axios correctly it give alot of TS errors
  //     {
  //       headers: { Authorization: 'Bearer ' + await this.getToken() }
  //     });



  //   debugger;
  //   try {
  //     const data = await response.clone().json();
  //     return data;
  //   } catch (err) {
  //     debugger;
  //     return await response.text();
  //   }
  // }


  async callCloudFunction(name, params) {
    const url = this.cloudFunctionHost + '/' + name;
    const res = await axios.get(url, {
      params: params,
      headers: { Authorization: 'Bearer ' + await this.getToken() }
    })


    return res.data;

  }




  async updateUserDetails(updates) {
    await firebase.database().ref("users").child(this.user.uid).update(updates)

  }

  async getProject(id) {
    try {
      const response: any = await this.callCloudFunction("getProject", { projectId: id })
      return response.content;
    } catch (err) {
      console.warn('error fetching proejct')
    }

    return null;


  }

  async getOwnedProjects() {
    try {
      const response: any = await this.callCloudFunction("getOwnedProjects", { userId: this.user.uid })
      this.ownedProjects = response.content;
      return response.content;
    } catch (err) {
      console.warn('error fetching proejct')
    }

    return null;


  }




}
