import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


import {
  Router,
  ActivatedRoute
} from '@angular/router';
import { environment } from '../../environments/environment';
import { FirebaseService } from '../firebase.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private firebaseService: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

  }

}
