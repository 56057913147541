<div style="text-align: right;" (click)="refreshTickets()">
  <mat-icon>refresh</mat-icon>
</div>
<div class="page-title">
  <button class="link" (click)="switchDisplayNewTicket()">
    <span>{{'New'|translate}}</span>
  </button>
</div>
<div class="card" id="newTicket">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'Type' | translate}}</mat-label>
    <mat-select [(ngModel)]="newTicketType" required>
      <mat-option value="accessProject">{{'accessProject' | translate}}</mat-option>
      <mat-option value="createProject">{{'createProject' | translate}}</mat-option>
      <mat-option value="loadProject">{{'loadProject' | translate}}</mat-option>
      <mat-option value="editProject">{{'editProject' | translate}}</mat-option>
      <mat-option value="Other">{{'Other' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'Projects' | translate}}</mat-label>
    <mat-select [(ngModel)]="newProjectId">
      <mat-option *ngFor="let project of listProjectNames" [value]="project.id">{{project.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'Title' | translate}}</mat-label>
    <input type="text" matInput [(ngModel)]="newTicketTitle" required>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'Description' | translate}}</mat-label>
    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="30" [(ngModel)]="newTicketEvent" required></textarea>
  </mat-form-field>
  <br>
  <div style="vertical-align: middle;margin-left: 20px;text-align: center;width: 100%;">
    <button mat-raised-button color="primary" (click)="openTicket()"
      [disabled]=" !newTicketType || !newTicketTitle || !newTicketEvent">{{'Submit' | translate}}</button>
  </div>
</div>
<br>
<br>
<div class="page-title">
  <span>{{'Open_tickets'|translate}} </span> <span class="ticket-count">({{openTickets.value.length}})</span>
  <div *ngIf="loadingOpenTickets" class="bimshow-logo-loading">
    <div class="square square1"></div>
    <div class="square square2"></div>
    <div class="square square3"></div>
  </div>
</div>
<div>
  <ngx-datatable *ngIf="openTickets.value.length > 0" class="material" [rows]="openTickets | async"
    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rowClass]="getRowClass"  (activate)="onActivate($event)"
    [limit]="10">
    <ngx-datatable-column prop="title" [name]="'Title' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="createdDate" [name]="'Date' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="type" [name]="'Type' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | translate }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="project" [name]="'Project' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | projectIdToName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="lastEvent" [name]="'Last event' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="status" [name]="'Status' | translate" [width]="100" headerClass="status">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span class="status-label mat-elevation-z2"> {{ value | translate }}</span>

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="id" name="Actions" [width]="100" [sortable]="false">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <button mat-raised-button (click)="openTicketDetails(value)">{{ 'HistoryAddEvent' | translate
          }}</button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<br>
<br>
<div class="page-title">
  <span>{{'Closed_tickets'|translate}} </span><span class="ticket-count">({{closedTickets.value.length}})</span>
  <div *ngIf="loadingClosedTickets" class="bimshow-logo-loading">
    <div class="square square1"></div>
    <div class="square square2"></div>
    <div class="square square3"></div>
  </div>
</div>
<div>
  <ngx-datatable *ngIf="closedTickets.value.length > 0" class="material" [rows]="closedTickets | async"
    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="10" (select)="openTicketDetails($event)">
    <ngx-datatable-column prop="createdDate" [name]="'Date' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="type" [name]="'Type' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | translate }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="project" [name]="'Project' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="title" [name]="'Title' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="lastEvent" [name]="'Last event' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="status" [name]="'Status' | translate" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | translate }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="id" [width]="100" [sortable]="false">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <button mat-raised-button (click)="setTicketStatus(value,'open')">{{ 'Reopen' | translate }}</button>
        <button mat-raised-button (click)="openTicketDetails(value)">{{ 'History' | translate }}</button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <app-ticket *ngIf="currentSelectedTicketId" [ticketId]="currentSelectedTicketId"
    (closed)="onTicketClosed()"></app-ticket>
</div>
