<app-loading *ngIf="waiting"></app-loading>

<ng-container *ngIf="!waiting">


	<mat-card class="flip-card-front" [ngClass]="{hide:signupMode}">
		<mat-card-title>
			{{'loginPage.title'|translate}}
		</mat-card-title>

		<mat-card-content>

			<form [formGroup]="loginForm" (submit)="onSubmit()">
				<mat-error *ngIf="(authError && loginForm.pristine)"> {{authError}} </mat-error>

				<mat-form-field>
					<input matInput type="email" placeholder="E-mail" formControlName="email" spellcheck="false">
					<mat-error *ngIf="(loginForm.controls.email.invalid && !loginForm.controls.email.untouched)">
						{{'loginPage.emailNoValid'|translate}}</mat-error>
				</mat-form-field>

				<mat-form-field>
					<input matInput type="password" placeholder="{{'loginPage.formProfil.password'|translate }}"
						formControlName="password">
					<mat-error *ngIf="(loginForm.controls.password.invalid&& !loginForm.controls.password.untouched)">
						{{'loginPage.requierPwd'|translate}}</mat-error>
				</mat-form-field>

				<button mat-raised-button type="submit" [disabled]="!loginForm.valid" color="primary"
					style="margin-top:10px; ">{{'loginPage.title'|translate}}</button>
					<div class="forgot-password" (click)="forgotPasswordClicked()" [ngClass]="{active:loginForm.controls.email.valid}">
						{{'loginPage.forgotPassword' | translate}}
					</div>

				<button mat-button style="flex-grow:1; margin-top:36px;" type="button"
					(click)="toggleSignupMode()">{{'loginPage.newUser'|translate}}</button>



			</form>


		


		</mat-card-content>
	</mat-card>
	<mat-card [ngClass]="{hide:!signupMode}">

		<mat-card-title>
			{{'loginPage.signup'|translate}}
		</mat-card-title>

		<mat-card-content>



			<form [formGroup]="signupForm" (submit)="onSignupSubmit()">
				<mat-error *ngIf="(signupError )"> {{signupError.message}} </mat-error>
				<div style="display:flex; flex-direction:row; justify-content:space-between">
					<mat-form-field style="width:45%">
						<input matInput type="text" placeholder="{{'loginPage.formProfil.FirstName'|translate}}"
							formControlName="firstName" spellcheck="false">

					</mat-form-field>

					<mat-form-field style=" width:45%;">
						<input matInput type="text" placeholder="{{'loginPage.formProfil.LastName' |translate}}"
							formControlName="lastName" spellcheck="false">

					</mat-form-field>
				</div>

				<mat-form-field>
					<input matInput type="email" placeholder="E-mail" formControlName="email" spellcheck="false"
						(input)="emailInput()">

				</mat-form-field>



				<mat-form-field>
					<input matInput type="password" placeholder="{{'loginPage.formProfil.password'|translate}}"
						formControlName="password" (input)="passwordInput()">
					<mat-error
						*ngIf="signupForm.controls.password.hasError('badPassword')">{{'loginPage.badPassword'|translate}}</mat-error>
				</mat-form-field>

				<mat-form-field>
					<input matInput type="password" placeholder="{{'loginPage.formProfil.verifyPassword' | translate}}"
						formControlName="verifyPassword">
					<mat-error
						*ngIf="signupForm.controls.verifyPassword.hasError('passwordMismatch')">{{'loginPage.passwordMismatch'|translate}}</mat-error>
				</mat-form-field>


				<div class="conditions">
					<mat-checkbox color="primary" class="conditionsCheckbox" formControlName="agreementAccepted">

					</mat-checkbox>
					<span (click)="openConditions()"
						[ngClass]="{'error': firstSignup ? false : signupForm.controls.agreementAccepted.hasError('required')}">{{'loginPage.conditionsName'|translate}}
						<a>{{'loginPage.conditionsName'|translate}}</a></span>
				</div>






				<button mat-raised-button color="primary" type="submit"
					[disabled]="signupForm.invalid">{{'loginPage.createUser'|translate}}</button>
				<button mat-button style="margin-top:10px" type="button"
					(click)="toggleSignupMode()">{{'loginPage.haveUser'|translate}}</button>

			</form>



		</mat-card-content>
	</mat-card>





	<div class="conditions-container" *ngIf="conditionsOpen">
		<mat-card>
			<mat-card-title>
				{{'loginPage.conditionsName'|translate}}
			</mat-card-title>
			<mat-card-content>
				<p [innerHTML]="'loginPage.conditions' | translate">

				</p>

			</mat-card-content>
			<mat-card-actions style="display: flex; justify-content: flex-end;">
				<button mat-button (click)="closeConditions()">Close</button>
			</mat-card-actions>
		</mat-card>
	</div>

	<div class="other-signin">
		{{'loginPage.or'|translate}}

		<div class="google-signin" (click)="firebaseService.googleSignIn()">
			<img src="../../assets/images/google.png">
			<span>
				{{'loginPage.googleSignIn'|translate}}
			</span>
		</div>
	</div>
</ng-container>