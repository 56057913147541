<div id="ticket-wrapper" class="ticket-wrapper" (mousedown)="onWrapperClicked($event)">
    <app-loading *ngIf="!ticket || events.length == 0"></app-loading>
    <div class="ticket-container mat-elevation-z8" *ngIf="ticket && events.length != 0">
        <div class="ticket-header mat-elevation-z1">

            <div class="ticket-title">{{ticket.title}}</div>
            <div class="ticket-status">
                {{ticket.status}}
            </div>

            <div class="spacer"></div>
            <button mat-button (click)="closeTicket()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="ticket-content">
            <div class="ticket-events">
                <div class="ticket-event mat-elevation-z6" *ngFor="let event of events"
                    [ngClass]="{'support': event.createdBy == 'support'}">
                    <div class="ticket-event-title"> by
                        <span class="event-created-by"> {{event.createdBy == 'support' ? ('Support' | translate) :
                            ('User' | translate) }}</span> on
                        <span class="event-date"> {{event.date | date:'yyyy/MM/dd HH:mm' }}</span>
                    </div>
                    <div class="ticket-event-content">
                        {{event.content}}
                    </div>

                </div>

                <div class="new-ticket-event">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Write New Message</mat-label>
                        <textarea matInput placeholder="Write your message here..."
                            [(ngModel)]="newEventContent"></textarea>
                    </mat-form-field>

                    <button mat-raised-button color="primary" (click)="addNewEvent()"
                        [disabled]="(newEventContent == null) || addingNewEvent">
                        {{'Submit' | translate}}
                    </button>
                </div>
            </div>


        </div>

    </div>
</div>