import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectsComponent } from './projects/projects.component';
import { TicketsComponent } from './tickets/tickets.component';
import { LoadingComponent } from './loading/loading.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FirebaseService } from './firebase.service';
import { SubscriptionComponent } from './subscription/subscription.component';

const routes: Routes = [
  {path: 'loading', component: LoadingComponent  },
  {path: 'resetPassword', component: ResetPasswordComponent },
  {path: 'dashboard', canActivate: [FirebaseService], component: DashboardComponent  },
  {path: 'details', canActivate: [FirebaseService], component: UserDetailsComponent },
  {path: 'subscription', canActivate: [FirebaseService], component: SubscriptionComponent },
  {path: 'projects', canActivate: [FirebaseService], component: ProjectsComponent },
  {path: 'tickets', canActivate: [FirebaseService], component: TicketsComponent },
  {path: 'tickets/:action/:id', canActivate: [FirebaseService], component: TicketsComponent },
  { path: '', redirectTo: 'details', pathMatch: 'full' },
  { path: '**', redirectTo: 'details' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
