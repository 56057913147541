import { Injectable, Injector, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { FirebaseService } from '../firebase.service';
import { tap, catchError, finalize, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { SnackbarComponent } from "../snackbar/snackbar.component";
import { environment } from '../../environments/environment';
import { APP_BASE_HREF } from '@angular/common';

@Injectable()
export class ManagedInterceptor implements HttpInterceptor {

  constructor(
    private snackBar: SnackbarComponent,
    private injector: Injector,
    @Inject(APP_BASE_HREF) private baseHref: string) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("INTERCEPTOR request URL " + request.url);

    if (
      request.url === environment.users_service.scheme + "://" + environment.users_service.host + "/users" &&
      request.method === "POST"
    ) {
      return next.handle(request);
    }
    else {
      if (
        request.url.includes("bimshow")
      ) {
        const started = Date.now();
        const firebaseService = this.injector.get(FirebaseService);
        return from(firebaseService.getToken()).pipe(
          switchMap(
            token => {
              request = request.clone({
                setHeaders: {
                  Authorization: 'Bearer ' + token
                }
              });
              return next.handle(request).pipe(
                tap(
                  (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                      //this.snackBar.openSnackBar(event.body.result + " " + event.body.content,null,null);
                    }
                    return event;
                  },
                  (error: HttpErrorResponse) => {
                    console.log("ERROR HTTP " + JSON.stringify(error));
                    this.snackBar.openSnackBar('ERROR ' + error.error.error, 'Close', 'error');
                  }
                ),
                // Log when response observable either completes or errors
                finalize(() => {
                  const elapsed = Date.now() - started;
                  const msg = `${request.method} "${request.urlWithParams}" in ${elapsed} ms.`;
                  console.log(msg);
                })
              );
            }
          )
        )
      }
      else {
        if (request.url.includes("assets/i18n")) {
          console.log("interceptor assets/i18n " + this.baseHref + " / " + request.url)
          var pathnamePrefix = this.baseHref;
          var newUrl = request.url.replace("/assets/i18n", pathnamePrefix + "assets/i18n")
          const dupReq = request.clone({ url: newUrl });
          return next.handle(dupReq);
        }
        else {
          return next.handle(request);
        }
      }
    }
  }
}
