import { Component } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  constructor(public snackBar: MatSnackBar) {}

          // this function will open up snackbar on top right position with custom background color (defined in css)
	openSnackBar(message: string, action: string, type: string) {
    var duration = 2000;
    var panelClass = null;
    if ( type === 'error'){
      duration = null;
      panelClass = 'snackBarError';
    }
    let snackBarRef = this.snackBar.open(message, action, {
      duration : duration,
      panelClass: [panelClass]
    });
    snackBarRef.onAction().subscribe(
      () => {
        console.log('snackbar action');
      }
    );
  }

}
