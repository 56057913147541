import { Component, ViewChild, OnInit } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketOpenDialog, TicketDetailsDialog } from '../tickets/tickets.component'
import { TicketsService } from '../tickets-api.service';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  errors: any = {};
  tableOwnedProjects: BehaviorSubject<any[]> = new BehaviorSubject<[]>([]);
  ownedProjectsDetails: any[] = [];
  loading: boolean;
  deleteInProgress = false;
  listTickets: any;

  //GUI
  ownedOffset: number = 0;


  constructor(
    private firebaseService: FirebaseService,
    public translate: TranslateService,
    private ticketsService: TicketsService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    //init projects details
    this.firebaseService.userLoggedin.subscribe(
      async () => {
        this.loading = true;

        try {


          this.ownedProjectsDetails = this.firebaseService.ownedProjects;
          this.ownedProjectsDetails.forEach(project => {
            project.thumbImage = project.defaultViewData || project.fallbackThumb
          })
          this.tableOwnedProjects.next(this.ownedProjectsDetails.slice());

        } catch (err) {
          //
        }

        this.loading = false;



      }
    )

    this.ticketsService.openTickets.subscribe(
      tickets => {
        this.listTickets = {}
        for (const ticket in tickets) {
          if (!this.listTickets.hasOwnProperty(tickets[ticket].project)) this.listTickets[tickets[ticket].project] = []
          this.listTickets[tickets[ticket].project].push(tickets[ticket].id)
        }
      }
    )


  }

  getOwnedProjects() {

  }

  

  deleteProject(pid, name) {
 
    this.translate.get('deleteProjectConfirm').subscribe(async (res: string) => {
      this.deleteInProgress = true;
      if (confirm(res + " " + name + "?")) {
        await this.firebaseService.callCloudFunction('deleteProject', { projectId: pid })
        const index = this.ownedProjectsDetails.findIndex(p => (p.id === pid));
        this.ownedProjectsDetails.splice(index, 1)
        this.tableOwnedProjects.next(this.ownedProjectsDetails.slice());
      
      }
      this.deleteInProgress = false;
   
    });
  }

  openProjectInApp(pid) {
    //window.location.href = environment.web_app_link+'?p='+pid;
    window.open(environment.web_app_link + '?p=' + pid, '_blank');
  }

  //if trigger each time filter change
  updateFilter(event, type) {
    const val = event.target.value.toLowerCase();
    // filter our data
    if (type == 'owned') {
      const temp = this.ownedProjectsDetails.filter(function (d) {
        if (d.name) {
          return d.name.toLowerCase().indexOf(val) !== -1 || d.id.toLowerCase().indexOf(val) !== -1 || !val;
        }
        else {
          return false;
        }
      });
      // update the rows
      this.tableOwnedProjects.next(temp);
    }

  }

  //open ticket dialog box
  openTicketDialog(projectId) {
    const data = {
      data: {
        userId: this.firebaseService.user.uid,
        projectId: projectId
      },
      height: '500px',
      width: '1000px'
    }
    const dialogRef = this.dialog.open(TicketOpenDialog, data);

    dialogRef.afterClosed().subscribe(
      dialog_result => {
        if (dialog_result) {
          //this.initTickets();
          console.log('close dialog ticket details');
        }
      }
    );
  }
  /*
   *open the dialog to create or edit a texture
   */
  openDetailsDialog(ticketId, mode) {
    const data = {
      data: {
        userId: this.firebaseService.user.uid,
        ticketId: ticketId,
        mode: mode
      },
      height: '800px',
      width: '1000px'
    }
    const dialogRef = this.dialog.open(TicketDetailsDialog, data);

    dialogRef.afterClosed().subscribe(
      dialog_result => {
        if (dialog_result) {
          //this.initTickets();
          console.log('close dialog ticket details');
        }
      }
    );
  }

  //for details of project
  @ViewChild('tableOwned') tableOwned: any;
  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.tableOwned.rowDetail.toggleExpandRow(row);
  }
  onPage(event, type) {
    this.ownedOffset = event.offset;
  }
  openTicketComparator(propA, propB) {

    if (propA.length < propB.length) {
      return -1
    }
    if (propA.length > propB.length) {
      return 1
    }
    return 0
  }
}
