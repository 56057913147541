import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from '../firebase.service';

import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isAuth: boolean;
  name: string;
  initials: string;
  pictureDownloadUrl: string;
  userTheme: string = null;
  constructor(
    public translate: TranslateService,
    public firebaseService:FirebaseService,
    private router: Router) { }

  ngOnInit(): void {
    this.firebaseService.userLoggedin.subscribe(
      () => {
        const user = this.firebaseService.userDetails;
        this.isAuth = true;
        if (user ) {
          this.name = user.lastName+" "+user.firstName;
          this.initials = user.firstName[0]+user.lastName[0];
          if ("theme" in  user){
            this.userTheme = null;
          }
        }
        this.pictureDownloadUrl = user.pictureDownloadUrl;
      }
    );
  }

  onSignOut() {
    this.firebaseService.signOutUser();
  }

  goWebsite(){
    window.location.href = environment.websiteURL;
  }

  goToApp(){
    window.location.href = environment.web_app_link;
  }

}
