<div
  class="card card_center">
  <p
    class="text-danger"
    *ngIf="errorMessage">{{ errorMessage }}</p>
  <p
    class="hello"
    *ngIf="!errorMessage">{{'PasswordResetForm' | translate}}</p>
  <br>
  <form [formGroup]="authFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>{{'Password' | translate}}</mat-label>
      <input
        matInput
        formControlName="newPassword"
        required
        [type]="hidePassword ? 'password' : 'text'">
      <button
        mat-icon-button matSuffix
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-hint>{{'Password_hint' | translate}}</mat-hint>
    </mat-form-field>
    <br>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>{{'Password_confirm' | translate}}</mat-label>
      <input
        matInput
        formControlName="confirmPassword"
        required
        [type]="hidePassword ? 'password' : 'text'">
      <button
        mat-icon-button matSuffix
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-hint>{{'Password_hint' | translate}}</mat-hint>
    </mat-form-field>
    <br>
    <br>
    <div
      *ngIf="authFormGroup.errors?.passwordConfirmed"
      class="text_error">
      <span>{{'Password_not_matching' | translate}}</span>
    </div>
  </form>
  <br>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!authFormGroup.valid"
    (click)="handleResetPassword()">{{'Submit' | translate}}</button>
</div>
