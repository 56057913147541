
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/firebase.service';
import { TicketsService } from 'src/app/tickets-api.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit, OnDestroy {
  @Input('ticketId') ticketId;
  @Output() closed = new EventEmitter<any>();
  events = [];
  ticket = null;
  newEventContent = null;
  addingNewEvent = false;
  subscriptions: Subscription[] = [];

  constructor(private firebaseService: FirebaseService, private ticketsService: TicketsService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log('current ticket', this.ticketId)


    this.initData();


  }

  ngOnDestroy(): void {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  async initData() {




    this.ticketsService.setTicket(this.ticketId);
    await this.ticketsService.setTicketEvents(this.ticketId);

    this.subscriptions.push(this.ticketsService.getTicket().subscribe(
      (ticket) => {

        if (ticket) {
          if (ticket.id == this.ticketId) {
            console.log('in sub ' + this.ticketId, ticket)
            this.ticket = ticket;
          }

        }
      }
    ));



    this.subscriptions.push(this.ticketsService.getTicketEvents().subscribe(events => {

      this.events = events;
    }));


    this.cdr.detectChanges();

  }

  async addNewEvent() {
    this.addingNewEvent = true;
    try {
      await this.ticketsService.addEventToTicket(this.ticket.id, { content: this.newEventContent, createdBy: this.firebaseService.user.uid });
      this.newEventContent = null;
    } catch (err) {
      console.warn('Error sending event')
    }

    this.addingNewEvent = false;
  }

  onWrapperClicked(event: PointerEvent) {
    if ((event.target as HTMLElement).id == 'ticket-wrapper') { this.closeTicket() }
  }

  closeTicket() {
    this.closed.emit();



  }



}
