<div class="card card_center">
  <form [formGroup]="formGroup">
    <mat-grid-list cols="2" rowHeight="30em">
      <mat-grid-tile>
        <mat-grid-tile-header>
          <span>{{'Personal Details'|translate}}</span>
        </mat-grid-tile-header>
        <div style="display: inline-block;vertical-align: middle;" class="dropzone" appDnd
          (fileDropped)="updateAvatar($event)">
          <img [src]="user.pictureDownloadUrl" class="avatar_img">
          <br>
          <input class="input_file" type="file" id="input_file"
            (change)="updateAvatar($event.target.files.item(0))">
          <label for="input_file">
            <mat-icon>cloud_upload</mat-icon>
          </label>

        </div>
        <div style="display: inline-block;vertical-align: middle;margin-left: 20px;">
          <div>
            
          </div>
          <mat-form-field appearance="fill">
            <mat-label>{{'Email' | translate}}</mat-label>
            <input disabled matInput [value]="user.email">
          </mat-form-field>
          <button [disabled]="resetPasswordEmailSent" mat-raised-button color="info" style="margin:8px; transform: translateY(-8px);"
            (click)="sendPasswordResetEmail()">
            <span *ngIf="resetPasswordEmailSent"> {{'EmailSentTo' | translate}} {{user.email}}</span>
            <ng-template [ngIf]="!resetPasswordEmailSent">
              <mat-icon>lock_reset</mat-icon> {{'Reset Password' | translate}}
            </ng-template>

          </button>

          <br>
          <mat-form-field appearance="fill">
            <mat-label>{{'Lastname' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.lastName" formControlName="lastname" required>
            <mat-hint>{{'Lastname_hint' | translate}}</mat-hint>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>{{'Firstname' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.firstName" formControlName="firstname" required>
            <mat-hint>{{'Firstname_hint' | translate}}</mat-hint>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>{{'Organization' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.organization" formControlName="organization">
            <mat-hint>{{'Organization_hint' | translate}}</mat-hint>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-grid-tile-header>{{'address'|translate}}</mat-grid-tile-header>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>{{'Number' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.address.number" formControlName="number">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{'Street' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.address.street" formControlName="street">
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>{{'Additional' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.address.additional" formControlName="additional">
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>{{'Zipcode' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.address.zipcode" formControlName="zipcode">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{'City' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.address.city" formControlName="city">
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>{{'Country' | translate}}</mat-label>
            <input matInput [(ngModel)]="user.address.country" formControlName="country">
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  <div style="text-align: center;width: 100%;">
    <button mat-raised-button color="primary" (click)="saveIdentityAdress()" [disabled]="!formGroup.valid">{{'Save' |
      translate}}</button>
  </div>
  <br>
</div>