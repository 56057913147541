import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})

export class SigninComponent implements OnInit {

  loginForm: FormGroup;
  signupForm: FormGroup;
  authError = null;
  signupError = null;
  waiting = false;
  signupMode = false;
  inAnimation = false;
  firstSignup = true;
  conditionsOpen = false;

  isLocalhost = window.location.host.includes('localhost') || window.location.host.includes('4200');

  constructor(public firebaseService: FirebaseService, private route: ActivatedRoute, private router: Router, public translate: TranslateService, private snackBar: SnackbarComponent) { }


  ngOnInit() {
    if (this.firebaseService.user) {
      this.router.navigate(['/details'])
    }


    this.initLoginForm();
    this.initSignupForm();


  }

  initSignupForm() {
    this.signupForm = new FormGroup({
      'firstName': new FormControl(null, Validators.required),
      'lastName': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email, this.emailHasFirebaseErrors.bind(this)]),
      'password': new FormControl(null, [Validators.required, this.passwordHasFirebaseErrors.bind(this), this.passwordRules.bind(this)]),
      'verifyPassword': new FormControl(null, [Validators.required, this.passwordVerifier.bind(this)]),
      'agreementAccepted': new FormControl(null, Validators.requiredTrue)
    })
  }

  initLoginForm() {
    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    })
  }

  onSubmit() {

    if (!this.loginForm.valid) {

      return;
    }

    this.waiting = true;
    this.firebaseService.signInUser(this.loginForm.value.email, this.loginForm.value.password).then(
      resolved => {


      },
      rejected => {


        if (rejected.code == 'auth/user-not-found') {
          this.authError = 'E-mail does not exist. Please try again';
        }

        if (rejected.code == 'auth/wrong-password') {
          this.authError = 'Password is incorrect. Please try again';
        }

        this.loginForm.reset();
        this.loginForm.markAsPristine();
        this.loginForm.markAsUntouched();
        this.loginForm.updateValueAndValidity();
        this.waiting = false;



      }
    ).catch(err => {
      console.warn(err)
    })

  }



  toggleSignupMode() {

    this.inAnimation = true;
    this.signupMode = !this.signupMode;

    setTimeout(() => {

      this.inAnimation = false;
    }, 500)
  }

  onSignupSubmit() {
    this.firstSignup = false;
    if (!this.signupForm.valid) {

      return;
    }

    this.signupError = null;
    this.waiting = true;
    this.firebaseService.createNewUser(this.signupForm.value).then(
      resolved => {

        this.firebaseService.signInUser(this.signupForm.value.email, this.signupForm.value.password).then(
          resolved => {


          },
          rejected => {
            this.waiting = false;
            console.warn('login failed after signup')
          }
        ).catch(err => {
          console.warn(err)
        })
      },
      rejected => {

        this.signupError = rejected;
        //this.signupForm.reset();
        //this.signupForm.markAsPristine();
        //this.signupForm.markAsUntouched();
        this.signupForm.controls.password.setValue(null)
        this.signupForm.controls.verifyPassword.reset();
        this.signupForm.updateValueAndValidity();
        this.waiting = false;
      })
  }

  emailHasFirebaseErrors(control: FormControl): { [s: string]: boolean } {
    let errorCodes = ['auth/email-already-in-use', 'auth/invalid-email']
    if (this.signupError) {
      if (errorCodes.indexOf(this.signupError.code) !== -1) {
        return { 'emailHasFirebaseErrors': true };
      }
    }


    return null;
  }

  passwordRules(control: FormControl): { [s: string]: boolean } {
    const reg = new RegExp(/^[0-9a-zA-Z_\-!@#$%^&*\(\)]{6,15}$/)

    if (!(control.value || '').match(reg)) {
      return { 'badPassword': true }
    }
    return null;
  }

  passwordHasFirebaseErrors(control: FormControl): { [s: string]: boolean } {
    let errorCodes = ['auth/weak-password']
    if (this.signupError) {
      if (errorCodes.indexOf(this.signupError.code) !== -1) {

        return { 'passwordHasFirebaseErrors': true };
      }
    }


    return null;
  }

  passwordVerifier(control: FormControl): { [s: string]: boolean } {
    if (this.signupForm) {

      if (this.signupForm.controls.password.value !== this.signupForm.controls.verifyPassword.value) {

        return { 'passwordMismatch': true };
      }
    }

    return null;

  }

  emailInput() {
    if (this.signupForm.controls.email.hasError('emailHasFirebaseErrors')) {
      this.signupError = null;
      this.signupForm.controls.email.updateValueAndValidity();


    }
  }

  passwordInput() {
    this.signupForm.controls.verifyPassword.setValue(null);
    if (this.signupForm.controls.password.hasError('passwordHasFirebaseErrors')) {

      this.signupError = null;
      this.signupForm.controls.password.updateValueAndValidity();


    }
  }



  openConditions() {
    this.conditionsOpen = true;
  }

  closeConditions() {
    this.conditionsOpen = false;
  }

  async forgotPasswordClicked() {
    this.waiting = true;
    try {
      await this.firebaseService.sendPasswordResetEmail(this.loginForm.controls.email.value);
      this.snackBar.openSnackBar(this.translate.instant('loginPage.passwordResetSend'), 'Close',null,20);
    } catch(err){
      this.snackBar.openSnackBar(this.translate.instant('loginPage.passwordResetError'), 'Close','error',20);
    }

    this.waiting = false;
    
  }
}
