import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


import {
  Router,
  ActivatedRoute
} from '@angular/router';
import { environment } from '../../environments/environment';
import { FirebaseService } from '../firebase.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private firebaseService: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    document.getElementById('splashscreen').style.display = "none";

    //TODO: REMOVE THIS MESS
    this.firebaseService.userLoggedin.subscribe(
      () => {

        if (this.router.url.startsWith('/loading')) {
          this.activatedRoute.queryParams.subscribe(params => {
            let previousRoute = params['previous'];

            this.router.navigate([previousRoute]);
          });
        }

        // if (user && user.init && user.userMga && this.router.url.startsWith('/loading')) {
        //   this.activatedRoute.queryParams.subscribe(params => {
        //     let previousRoute = params['previous'];

        //     this.router.navigate([previousRoute]);
        //   });
        // }
        // if (user && user.init && !user.isAuth) {
        //   window.location.href = environment.loginURL + "?originalUrl=" + this.firebaseService.originalUrl;
        // }
      }
    );
  }

}
