<div>

  <div class="page-title">
    <div *ngIf="loading" class="bimshow-logo-loading">
      <div class="square square1"></div>
      <div class="square square2"></div>
      <div class="square square3"></div>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <mat-form-field class="inputFilter" appearance="fill">
      <mat-label>{{'inputFilter' | translate}}</mat-label>
      <input matInput type="search" [placeholder]="'inputFilterProject' | translate"
        (keyup)="updateFilter($event, 'owned')" />
    </mat-form-field>
    <ngx-datatable #tableOwned *ngIf="tableOwnedProjects.value.length > 0" class="material"
      [rows]="tableOwnedProjects | async" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      rowHeight="auto" [limit]="10" [sorts]="[{ prop: 'name', dir: 'desc' }]" [offset]="ownedOffset"
      (page)="onPage($event,'owned')">

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="padding-left:35px;">
            <table>
              <tr>
                <td class="details-title">ID</td>
                <td>{{row.id}}</td>
              </tr>
              <tr>
                <td class="details-title">{{'StorageSize' | translate}}</td>
                <td>{{ row.storageSize/1024/1024 | number:'1.2-2' }} MB</td>
              <tr>

            </table>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <!-- columns -->
      <ngx-datatable-column prop="error" [name]="'Status' | translate" [width]="10">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <mat-icon *ngIf="value" [matTooltip]="value">error</mat-icon>
          <mat-icon *ngIf="!value" matTooltip="OK">check</mat-icon>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="thumbImage" [name]="" [width]="50">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <img [src]="value" />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="name" [name]="'Name' | translate" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="privacy" [name]="'Privacy' | translate" [width]="50">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value">{{ value | translate}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="openTickets" [name]="'OpenTicket' | translate" [width]="50"
        [comparator]="openTicketComparator">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value.length>0">{{ value.length }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="storageSize" [name]="'StorageSize' | translate" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value">{{ value/1024/1024 | number:'1.2-2' }} MB</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="id" name="" [width]="300" [sortable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <button mat-raised-button (click)="toggleExpandRow(row)">{{'Details' | translate }}</button>
          <button mat-raised-button (click)="openTicketDialog(value)">{{'OpenTicket' | translate }}</button>
          <button mat-raised-button (click)="openProjectInApp(value)" target="_blank">{{ 'OpenIt' | translate
            }}</button>
          <button mat-raised-button (click)="deleteProject(value,row.name)">{{ 'delete' | translate }}</button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </ng-container>


</div>