import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  loadingOpenTickets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingClosedTickets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingTicket: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingEvents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  closedTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  ticket: BehaviorSubject<any> = new BehaviorSubject<any>(null);;
  events: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {
    this.firebaseService.userLoggedin.subscribe(
      () => {
        this.getTicketsUsers();
      })

  }

  /*to handle http error*/
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  openTicketWithEvent(datas) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.firebaseService.cloudFunctionHost + '/openNewTicket', datas, { responseType: 'json' }).toPromise()
        .then(
          result => {
            this.getTicketsUsers();
            resolve(result);
          }
        )
        .catch(
          error => {
            this.handleError(error);
            reject(error)
          }
        )
    })

  }
  async addEventToTicket(id, datas) {
    const newEvent = JSON.parse(JSON.stringify(datas))
    newEvent['ticketId'] = id;
    await this.http.post<any>(this.firebaseService.cloudFunctionHost + "/addTicketEvent", newEvent, { responseType: 'json' }).toPromise()
      .then(
        result => {
          this.setTicketEvents(id);
        }
      )
      .catch(
        error => {
          this.handleError(error);
        }
      )
  }

  getTicketsUsers() {
    this.loadingOpenTickets.next(true);
    this.loadingClosedTickets.next(true);
    if (this.firebaseService.user) {
      this.firebaseService.callCloudFunction("getUserTickets", { userId: this.firebaseService.user.uid }).then(
        (listTicketIds: any) => {
          var listTicketIdsB = listTicketIds.content;
          var counter = 0;
          var openTickets = [];
          var closedTickets = [];
          if (!listTicketIds.content || listTicketIds.content.length == 0) {
            this.loadingOpenTickets.next(false);
            this.loadingClosedTickets.next(false);
          }
          for (const ticketId in listTicketIds.content) {
            this.fetchTicket(ticketId).toPromise()
              .then(
                ticket => {
                  if (ticket.content.status.startsWith('open')) {
                    openTickets.push(ticket.content)
                  }
                  if (ticket.content.status.startsWith('closed')) {
                    closedTickets.push(ticket.content)
                  }
                }
              )
              .catch(
                error => {
                  console.log("error ticketsDetails");
                }
              )
              .finally(() => {
                counter += 1;
                if (counter === Object.keys(listTicketIds.content).length) {
                  this.openTickets.next(openTickets)
                  this.closedTickets.next(closedTickets);

                  this.loadingOpenTickets.next(false);
                  this.loadingClosedTickets.next(false);
                }
              });
          }
        },
        error => {
          this.handleError(error);
        }
      );
    }
    else {
      this.openTickets.next([]);
      this.closedTickets.next([]);

      this.loadingOpenTickets.next(false);
      this.loadingClosedTickets.next(false);
    }
  }
  fetchTicket(id): Observable<any> {
    return this.http.get(this.firebaseService.cloudFunctionHost + "/getTicket", {
      responseType: 'json', params: {
        id: id
      }
    }).pipe(
      catchError(this.handleError)
    );
  }
  setTicket(id) {
    this.loadingTicket.next(true);
    this.http.get(this.firebaseService.cloudFunctionHost + "/getTicket", {
      responseType: 'json', params: {
        id: id
      }
    }).toPromise()
      .then(
        (ticket: any) => {
          this.ticket.next(ticket.content);
          this.loadingTicket.next(false);
        }
      )
      .catch(
        error => {
          this.handleError(error);
          this.loadingTicket.next(false);
        }
      )
  }
  async setTicketEvents(id) {
    this.loadingEvents.next(true);
    try {
      const events: any = await this.http.get(this.firebaseService.cloudFunctionHost + "/getTicketEvents", {
        responseType: 'json', params: {
          id: id
        }
      }).toPromise()

      var eventsToPush = [];
      for (const event in events.content) {
        eventsToPush.push(events.content[event]);
      }


      this.events.next(eventsToPush)



      this.loadingEvents.next(false);
      console.log('done', eventsToPush)

    }

    catch (error) {
      this.handleError(error);
      this.loadingEvents.next(false);
    }

  }

  getTicketEvents() {
    return this.events;
  }
  getTicket() {
    return this.ticket;
  }

}
