import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { environment } from './../environments/environment';
import { ReplaySubject } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  cloudFunctionHost = environment.cloundFunctionsHost;
  firebaseConfig = environment.firebaseConfig;
  providerGoogleSignIn;

  ownedProjects = [];

  userDetails = null
  userLoggedin = new ReplaySubject<any>(1);
  user = null;
  authCheck = true;
  currentGooogleSignInData = null;
  beforeLoginPath = null;
  redirectPath = null;

  constructor(
    public router: Router,
    protected translate: TranslateService) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get('redirect');
      if(redirect) {
        this.redirectPath = redirect;
      }
  
      
  


    firebase.initializeApp(this.firebaseConfig);


    this.getCurrentUserStatus();

    this.providerGoogleSignIn = new firebase.auth.GoogleAuthProvider();
    // To apply the default browser preference instead of explicitly setting it.
    firebase.auth().useDeviceLanguage();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user) {
      return true;
    } else {
      this.beforeLoginPath =route.routeConfig.path;
    }

  }

  googleSignIn() {
    firebase.auth()
      .signInWithPopup(this.providerGoogleSignIn)
      .then((result) => {
        this.currentGooogleSignInData = result
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log("error google sign in: " + errorCode + " / " + errorMessage + " / " + email)
      });
  }

  getCurrentUserStatus() {

    firebase.auth().onAuthStateChanged(
      async (user) => {

        if (user) {
          if (user.isAnonymous) {
            this.signOutUser();
          } else {
            this.user = user;
            await this.getUserData();
            this.authCheck = false;


            if(this.redirectPath) {
       
              window.location.replace( this.redirectPath);
              return;
            } else {
              this.router.navigate(['/' + this.beforeLoginPath || 'details'] );
            }
         
            
          

          }
        } else {
          this.router.navigate(['/signin']);
          this.authCheck = false;
        }


      }

    );
  }

  async getUserData() {


    this.userDetails = (await firebase.database().ref('users').child(this.user.uid).once("value")).val();
    if (!this.userDetails) {
        
      await this.createUserMissingData(
        {
          firstName: this.currentGooogleSignInData ? this.currentGooogleSignInData.additionalUserInfo.profile.given_name: (this.user.displayName || 'First Name'),
          lastName: this.currentGooogleSignInData ? this.currentGooogleSignInData.additionalUserInfo.profile.family_name : (this.user.displayName || 'Family Name'),
        }
      )
      this.userDetails = (await firebase.database().ref('users').child(this.user.uid).once("value")).val();
    }
    this.userDetails.id = this.user.uid;

    await this.getOwnedProjects();
    this.userLoggedin.next()
  }

  async createNewUser(newUserData) {
    const url = this.cloudFunctionHost + '/' + 'createUser';

    const res = await axios.post(url, newUserData)
  }

  async createUserMissingData(newUserData) {

    const url = this.cloudFunctionHost + '/' + 'createUser';

    const res = await axios.post(url, {
      ...newUserData,
      uid: this.user.uid,
      email: this.user.email
    },
      {
        headers: { Authorization: 'Bearer ' + await this.getToken() }
      }
    )
  }

  signInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          () => {
            resolve('');
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  checkIfUserLoggedAlready() {
    return new Promise<any>((resolve, reject) => {
      // Create a callback which logs the current auth state
      firebase.auth().onAuthStateChanged((user) => {

        if (user) {
          this.user = user;


          if (user.email) { //only if non anonnym...
            firebase
              .database()
              .ref("users")
              .child(user.uid)
              .update({ lastLogin: new Date().toUTCString() })
              .catch((err) => {
                //do nothing if error..
              });
          }

          firebase.auth().currentUser.getIdToken(true).then(
            (token) => {
              resolve(user);
            },
            (bad) => {
              reject(bad);
            }
          );
          // ...
        } else {
          // User is signed out.
          // ...

          reject();
        }
      });
    });
  }


  uploadFileStorage(file, path) {
    console.log("uploadFileStorage: " + path);
    var storageRef = firebase.storage().ref();
    var newFileRef = storageRef.child(path);
    console.log("user id: " + firebase.auth().currentUser.uid);
    return newFileRef.put(file);
  }

  signOutUser() {
    this.authCheck = true;
    this.user = null;
    firebase.auth().signOut().then(() => { 
      this.router.navigate(['/']);
      location.reload();
    }).catch((error) => {
      // An error happened.
    });;
  }

  sendPasswordResetEmail(emailAddress) {
    firebase.auth().languageCode = this.translate.currentLang;

    return firebase.auth().sendPasswordResetEmail(emailAddress);
  }

  handleResetPassword(actionCode, newPassword) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
      var accountEmail = email;

      // Save the new password.
      firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
        window.location.href = environment.loginURL;
        //this.router.navigate(['/login']);
        // Password reset has been confirmed and new password updated.

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }).catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  getToken() {
    return firebase.auth().currentUser.getIdToken(false);
  }

  async getTotalViewsCountOfUid(uid) {
    const views = Number((await firebase.database().ref("viewsCount/byUser").child(uid).once('value')).val());
    return views;
  }



  async callCloudFunction(name, params) {
    const url = this.cloudFunctionHost + '/' + name;
    const res = await axios.get(url, {
      params: params,
      headers: { Authorization: 'Bearer ' + await this.getToken() }
    })


    return res.data;

  }




  async updateUserDetails(updates) {
    await firebase.database().ref("users").child(this.user.uid).update(updates)

  }

  async getProject(id) {
    try {
      const response: any = await this.callCloudFunction("getProject", { projectId: id })
      return response.content;
    } catch (err) {
      console.warn('error fetching proejct')
    }

    return null;


  }

  async getOwnedProjects() {
    try {
      const response: any = await this.callCloudFunction("getOwnedProjects", { userId: this.user.uid })
      this.ownedProjects = response.content;
      return response.content;
    } catch (err) {
      console.warn('error fetching proejct')
    }

    return null;


  }




}
