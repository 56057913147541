import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MenuComponent } from './menu/menu.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectsComponent } from './projects/projects.component';
import { TicketsComponent,TicketDetailsDialog, TicketOpenDialog } from './tickets/tickets.component';
import { UserDetailsComponent, DndDirective } from './user-details/user-details.component';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { MatCardModule } from '@angular/material/card';
import { httpInterceptorProviders } from './http-interceptors/index';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoadingComponent } from './loading/loading.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { ProjectIdToNamePipe } from './project-id-to-name.pipe';
import { TicketComponent } from './tickets/ticket/ticket.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SigninComponent } from './signin/signin.component';

import { MatCheckboxModule } from '@angular/material/checkbox';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    UserDetailsComponent,
    ProjectsComponent,
    TicketsComponent,
    DashboardComponent,
    DndDirective,
    SnackbarComponent,
    TicketDetailsDialog,
    TicketOpenDialog,
    ResetPasswordComponent,
    LoadingComponent,
    ProjectIdToNamePipe,
    TicketComponent,
    SubscriptionComponent,
    SigninComponent
  ],
  imports: [
    MatCardModule,    
    MatCheckboxModule,
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTooltipModule,
    NgxDatatableModule,
    MatBadgeModule,
    MatDialogModule,
    MatGridListModule,
    MatMenuModule,
    MatProgressBarModule,
    TranslateModule.forRoot({
          defaultLanguage: 'en',
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      })
  ],
  providers: [
    httpInterceptorProviders,
    SnackbarComponent,
    {
      provide: APP_BASE_HREF,
      useValue: window.location.pathname.startsWith('/account/') ? '/account/' : '/'
    }
  ],
  entryComponents: [
    TicketDetailsDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
