import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { FirebaseService } from '../firebase.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  user = null;
  isLoading = false;
  price1kViews = 15;
  viewsCreditToAdd = 1;
  projectsToAdd = 1;
  price1Project = 10;
  buyViewsDialogOpen = false;
  buyProjectsDialogOpen = false;
  currentPaymentCard = null;
  usersTotalViews = 0;
  currentTime = new Date().getTime();

  

  CHECKOUT_PRICES = environment.stripe.CHECKOUT_PRICES;

  cloudFunctionHost = environment.cloundFunctionsHost;

  constructor(private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.initUser();
  }

  initUser(): void {
    this.firebaseService.userLoggedin.subscribe(
      async () => {
        this.user = this.firebaseService.userDetails;
   
        this.getCurrentPaymentCardInfo();
     
        this.usersTotalViews = await this.firebaseService.getTotalViewsCountOfUid(this.user.id)
      }
    );
  }

  getViewsProgress(): number {
    return 100 * this.usersTotalViews / this.user.subscription.viewsCredit;
  }

  getTotalOwnedProjects(): number {

    return this.user.ownedProjects ? Object.keys(this.user.ownedProjects).length : 0;
  }

  getProjectsProgress(): number {

    return 100 * this.getTotalOwnedProjects() / this.user.subscription.maxProjects;
  }



  onAddViewsCreditChanged() {




  }

  onAddProjectsChanged() {
    this.projectsToAdd = Math.floor(this.projectsToAdd);
  }

  openBuyViewsDialog() {
    this.buyViewsDialogOpen = true;
  }

  openBuyProjectsDialog() {
    this.buyProjectsDialogOpen = true;
  }


  closeBuyViewsDialog() {
    this.buyViewsDialogOpen = false;
    this.viewsCreditToAdd = 1;

  }

  closeBuyProjectsDialog() {
    this.buyProjectsDialogOpen = false;
    this.projectsToAdd = 1;
  }

  async startNewSubscription() {

    const checkoutUrl = await (await this.firebaseService.callCloudFunction(
      'createCheckoutForUser',
      {
        userId: this.user.id,
        strpiePriceId: this.CHECKOUT_PRICES.SUBSCRIPTION,
        quantity: 1,
        subscription: true

      }
    ))
    window.location.href = checkoutUrl.url;


  }

  async updateSubscpritionPayment() {
    this.isLoading = true;
    try {
      const checkoutUrl = await (await this.firebaseService.callCloudFunction(
        'createUpdateSubscriptionPaymentCheckout',
        {
          userId: this.user.id
        }
      ))
      window.location.href = checkoutUrl.url;
    }

    catch (err) {
      this.isLoading = false;
    }




  }

  async getCurrentPaymentCardInfo() {



    this.currentPaymentCard = await (await this.firebaseService.callCloudFunction(
      'getCurrentSubscriptionPaymentCardEndsWith',
      {
        userId: this.user.id
      }
    ))

    const deltaYears = this.currentPaymentCard.exp_year - new Date().getFullYear();
    this.currentPaymentCard.expiresInMonths = (deltaYears) * 12 + this.currentPaymentCard.exp_month - (new Date().getMonth() + 1);




  }

  async setSubscriptionCancel(value) {
    this.isLoading = true;
    await this.firebaseService.callCloudFunction(
      'setCancelSubscriptionForUser',
      {
        userId: this.user.id,
        cancel_at_period_end: value
      }
    )

    window.location.href = window.location.href;
    this.isLoading = false;
  }

  async createProjectsCheckout() {
    this.isLoading = true;
    try {
      const checkoutUrl = await (await this.firebaseService.callCloudFunction(
        'createCheckoutForUser',
        {
          userId: this.user.id,
          strpiePriceId: this.CHECKOUT_PRICES.PROJECT_ADD_1,
          quantity: this.projectsToAdd,

        }
      ))
      window.location.href = checkoutUrl.url;
    }
    catch (err) {
      this.isLoading = false;
    }


  }

  async createViewsCreditCheckout() {
    this.isLoading = true;
    try {
      const checkoutUrl = await (await this.firebaseService.callCloudFunction(
        'createCheckoutForUser',
        {
          userId: this.user.id,
          strpiePriceId: this.CHECKOUT_PRICES.VIEWS_ADD_1000,
          quantity: this.viewsCreditToAdd,

        }
      ))

      window.location.href = checkoutUrl.url;
    }
    catch (err) {
      this.isLoading = false;
    }
  }




}


