import {
  Component,
  OnInit,
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
import { FirebaseService } from '../firebase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  user: any;
  setIdentityRunning = false;
  setAddressRunning = false;
  setAppSettingsRunning = false;
  setAvatarRunning = false;
  resetPasswordEmailSent = false;
  currentDate = Date.now();
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService,
    public translate: TranslateService) { }

  ngOnInit(): void {


    this.initUser();
    this.initForm();

  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      firstname: [null, [Validators.required, Validators.pattern(/[a-zA-Z]{2,}/)]],
      lastname: [null, [Validators.required, Validators.pattern(/[a-zA-Z]{2,}/)]],
      organization: [null],
      number: [null],
      street: [null],
      zipcode: [null],
      city: [null],
      country: [null],
      additional: [null]
    });
  }

  initUser(): void {
    this.user = this.firebaseService.userDetails
    if(!this.user.address) {
      this.user.address = {
        
      }
    }
  }


  updateAvatar(file) {




    var reader = new FileReader();
    reader.onload = (event: any) => {
      var image = new Image();

      //Validate the File Height and Width.
      image.onload = () => {
        const imageWidth = image.naturalWidth;
        const imageHeight = image.naturalHeight;


        // Create a canvas with the desired dimensions
        var canvas = document.createElement("canvas");
        if (imageHeight > imageWidth) {
          let height = Math.min(imageHeight, 200)
          canvas.width = imageWidth * height / imageHeight;
          canvas.height = height;

        } else {
          let width = Math.min(imageWidth, 200)

          canvas.width = width;
          canvas.height = imageHeight * width / imageWidth;
        }

        // Scale and draw the source image to the canvas
        canvas.getContext("2d").drawImage(image, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a data URL in PNG format
        const imgData = canvas.toDataURL();
        this.user.pictureDownloadUrl = imgData;


      };

      image.onerror = () => {
        console.log('error avatar load')
      };

      if (typeof event.target.result == 'string') {
        image.src = event.target.result;

      }





    }

    reader.readAsDataURL(file)


  }

  saveIdentityAdress() {

    let updates = this.fixUpdatesJson({
      address: this.fixUpdatesJson(this.user.address),
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      organization: this.user.organization,

      pictureDownloadUrl: this.user.pictureDownloadUrl

    });


    this.firebaseService.updateUserDetails(updates);

  }

  fixUpdatesJson(object) {
    const cloned = JSON.parse(JSON.stringify(object))
    for (let key in cloned) {
      if (cloned[key] === undefined || cloned[key] === "") {
        cloned[key] = null;
      }


    }

    return cloned;
  }





  sendPasswordResetEmail() {
    this.firebaseService.sendPasswordResetEmail(this.user.email)
      .then(
        result => {
          // Email sent.
          console.log("email reset password sent");
          this.resetPasswordEmailSent = true;
        })
      .catch(
        error => {
          // An error happened.
          console.log("ERROR email reset password not sent " + error.message);
        });
  }
}


@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    console.log("fileover event");
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    console.log("drop event");
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files.item(0));
    }
  }
}
