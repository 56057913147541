<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="newTicketCreated">
    <mat-icon>close</mat-icon>
  </button>
</div>
<br>
<br>
<mat-form-field appearance="fill" class="full-width">
  <mat-label>{{'Type' | translate}}</mat-label>
  <select matNativeControl [(ngModel)]="newTicketType" required>
    <option value="accessProject">{{'accessProject' | translate}}</option>
    <option value="createProject">{{'createProject' | translate}}</option>
    <option value="loadProject">{{'loadProject' | translate}}</option>
    <option value="editProject">{{'editProject' | translate}}</option>
    <option value="Other">{{'Other' | translate}}</option>
  </select>
</mat-form-field>
<br>
<mat-form-field appearance="fill" class="full-width">
  <mat-label>{{'Projects' | translate}}</mat-label>
  <select matNativeControl [(ngModel)]="newProjectId">
    <option
    value="null"
    ></option>
    <option
      *ngFor="let project of listProjectNames"
      [value]="project.id"
      >{{project.name}}</option>
  </select>
</mat-form-field>
<br>
<mat-form-field appearance="fill" class="full-width">
  <mat-label>{{'Title' | translate}}</mat-label>
  <input type="text" matInput [(ngModel)]="newTicketTitle" required>
</mat-form-field>
<br>
<mat-form-field appearance="fill" class="full-width">
  <mat-label>{{'Description' | translate}}</mat-label>
  <textarea
    matInput
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="5"
    cdkAutosizeMaxRows="30"
    [(ngModel)]="newTicketEvent"
    required></textarea>
</mat-form-field>
<br>
<div
  style="vertical-align: middle;margin-left: 20px;text-align: center;width: 100%;">
  <button
    mat-raised-button
    color="primary"
    (click)="openTicket()"
    [disabled]=" !newTicketType || !newTicketTitle || !newTicketEvent"
    >{{'Submit' | translate}}</button>
</div>
