import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketsService } from '../tickets-api.service';
import { FirebaseService } from '../firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  listTicketIds: any;
  openTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  loadingOpenTickets: boolean;
  loadingClosedTickets: boolean;
  closedTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  userId: string;
  newTicketTitle: string;
  newTicketType: string;
  newProjectId: string = null;
  newProjectName: string = null;
  newTicketEvent: string;
  listProjectNames: any[] = [];
  createNewTicket = false;

  currentSelectedTicketId = null;


  constructor(
    private route: ActivatedRoute,
    private _ngZone: NgZone,
    private ticketsService: TicketsService,
    private firebaseService: FirebaseService,
    public translate: TranslateService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.checkParams()

    this.firebaseService.userLoggedin.subscribe(() => {
      //init content
      this.openTickets = this.ticketsService.openTickets;
      this.closedTickets = this.ticketsService.closedTickets;
      this.ticketsService.loadingOpenTickets.subscribe(
        state => this.loadingOpenTickets = state
      );
      this.ticketsService.loadingClosedTickets.subscribe(
        state => this.loadingClosedTickets = state
      );
      this.userId = this.firebaseService.user.uid;



      this.listProjectNames = [{ name: '', id: 'null' }];

      for (const project of this.firebaseService.ownedProjects) {
        this.listProjectNames.push({ name: project.name, id: project.id });

      }
    })



  }

  checkParams() {
    //check the routing parameters
    const routeParams = this.route.snapshot.paramMap;
    const actionFromRoute = routeParams.get('action');
    const idFromRoute = routeParams.get('id');
    if (actionFromRoute == 'create') {
      if (idFromRoute) {
        this.switchDisplayNewTicket()
        this.newProjectId = idFromRoute
      }
    }
  }
  refreshTickets() {
    this.ticketsService.getTicketsUsers();
  }

  async openTicket() {
    const datas = {
      title: this.newTicketTitle,
      user: this.userId,
      type: this.newTicketType,
      event: this.newTicketEvent,
      project: this.newProjectId === 'null' ? null : this.newProjectId
    }
    try {
      await this.ticketsService.openTicketWithEvent(datas);
    }
    catch (error) {
      //to do: snackbar alert
      console.log('Error when creating ticket ' + error.message)
    }

    this.createNewTicket = false;
  }

  onActivate(event) {
    if (event.type == 'click') {
      this.openTicketDetails(event.row.id);
    }

  }

  openTicketDetails(ticketId) {
    this.currentSelectedTicketId = ticketId;
  }


  /*
  *open the dialog to create or edit a ticket
  */
  openDetailsDialog(ticketId, mode) {
    this.currentSelectedTicketId = ticketId;
    const data = {
      data: {
        userId: this.userId,
        ticketId: ticketId,
        mode: mode
      },
      height: '800px',
      width: '1000px'
    }
    this.ticketsService.setTicket(ticketId);
    const dialogRef = this.dialog.open(TicketDetailsDialog, data);

    dialogRef.afterClosed().subscribe(
      dialog_result => {
        if (dialog_result) {
          //this.initTickets();
          console.log('close dialog ticket details');
        }
      }
    );
  }

  onTicketClosed() {
    this.currentSelectedTicketId = null;
  }

  switchDisplayNewTicket() {
    this.createNewTicket = !this.createNewTicket;
    
    this.newTicketTitle = null
    this.newTicketType = null
    this.newProjectId = null;
    this.newProjectName= null;
    this.newTicketEvent = null
  
  }

  //for textarea dynamic height
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  //for table css class
  getRowClass(row) {
    return {
      'warnUser': row.status === 'open - waiting for user'
    };
  }
}

/*
 * for modal popup
 */
@Component({
  selector: 'ticket-details',
  templateUrl: 'ticket-details.html',
  styleUrls: ['ticket-details.scss']
})

export class TicketDetailsDialog {

  ticket: any = {};
  events: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  setAddEventRunning: boolean = false;
  newEventDatas: any = {};
  newEventCreated: boolean = false;
  loadingTicket: boolean;
  loadingEvents: boolean;

  constructor(
    private _ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data,
    private ticketsService: TicketsService,
  ) {
    this.newEventDatas = {
      content: null,
      createdBy: data.userId
    }

    this.ticketsService.getTicket().subscribe(
      (ticket) => {
        if (ticket) {
          this.ticket = ticket;
        }
      }
    );
    this.ticketsService.setTicket(data.ticketId);

    this.events = this.ticketsService.getTicketEvents();

    this.ticketsService.setTicketEvents(data.ticketId);
    this.ticketsService.loadingTicket.subscribe(
      state => {
        this.loadingTicket = state;
      }
    );
    // this.ticketsService.loadingEvents.subscribe(
    //   state => {
    //     this.loadingEvents = state;

    //     setTimeout(() => {
    //       this.table.rowDetail.expandAllRows();
    //     }, 2000);
    //   }
    // );
  }

  //for textarea dynamic height
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  //event functions
  addEvent() {
    this.ticketsService.addEventToTicket(this.ticket.id, this.newEventDatas);
  }

  //for detail expand
  @ViewChild('myTable') table: any;
  expanded: any = {};
  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

}

/*
 * for modal popup
 */
@Component({
  selector: 'ticket-open',
  templateUrl: 'ticket-open.html',
  styleUrls: ['ticket-open.scss']
})

export class TicketOpenDialog {
  newTicketTitle: string;
  newTicketType: string;
  newProjectId: string;
  newTicketEvent: string;
  newTicketCreated: boolean = false;
  listProjectNames: any[] = [];


  constructor(
    private _ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data,
    private firebaseService: FirebaseService,
    private ticketsService: TicketsService,
    private dialogRef: MatDialogRef<TicketOpenDialog>
  ) {

    

    this.newProjectId = this.data.projectId ? this.data.projectId : null
    for (const project of this.firebaseService.ownedProjects) {
      this.listProjectNames.push({ name: project.name, id: project.id });

    }
    console.log('this.newProjectId ' + this.newProjectId)
  }

  openTicket() {
    const datas = {
      title: this.newTicketTitle,
      user: this.data.userId,
      type: this.newTicketType,
      event: this.newTicketEvent,
      project: this.newProjectId
    }
    try {
      this.ticketsService.openTicketWithEvent(datas);
      this.dialogRef.close();
    }
    catch (error) {
      console.log("Error when creating ticket " + error.message);
    }
  }

  //for textarea dynamic height
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
}
