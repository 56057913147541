import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FirebaseService } from './firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bimshow-account';
  isAuth = false;
  userTheme: string = null;
  constructor(
    public translate: TranslateService,
    public router: Router,
    public firebaseService: FirebaseService
  ) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.firebaseService.userLoggedin.subscribe(
      () => {
        this.isAuth = true;
        if ("theme" in this.firebaseService.userDetails) {
          this.userTheme = null;
        }
      }
    );
  }
}
