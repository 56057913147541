<div [ngClass]="{'menu' : userTheme}">
  <!--<div class="menu-button" [ngClass]="{'selected':selectedMode  == 'dashboard'}" routerLink="/dashboard">
      <mat-icon>dashboard</mat-icon>
      <span>{{'Dashboard' | translate}}</span>
  </div>-->
  <div class="menu-button item" [ngClass]="{'selected':selectedMode  == 'details'}" routerLink="/details">
    <mat-icon>person_pin</mat-icon>
    <span>{{'Details' | translate}}</span>
  </div>
  <div class="menu-button item" [ngClass]="{'selected':selectedMode  == 'subscription'}" routerLink="/subscription">
    <mat-icon>credit_card</mat-icon>
    <span>{{'Subscription' | translate}}</span>
  </div>
  <div class="menu-button item" [ngClass]="{'selected':selectedMode  == 'projects'}" routerLink="/projects">
    <mat-icon>folder</mat-icon>
    <span>{{'Projects' | translate}}</span>
  
  </div>
  <div class="menu-button item" [ngClass]="{'selected':selectedMode  == 'tickets'}" routerLink="/tickets">
    <mat-icon>bug_report</mat-icon>
    <span>{{'Tickets' | translate}}</span>
   
  </div>
</div>