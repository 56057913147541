import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl } from '@angular/forms';

export const passwordConfirmedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');

  return confirmPassword && password && password.value != confirmPassword.value ? { passwordConfirmed: true } : null;
};

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  actionCode: string;
  continueUrl: string;
  mode: string;
  errorMessage: string;
  authFormGroup: FormGroup;
  hidePassword: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService,
    public translate: TranslateService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.authFormGroup = this.formBuilder.group({
      newPassword: [null, [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],
      confirmPassword: [null, [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]]
    }, { validators: passwordConfirmedValidator });

    this.actionCode = this.route.snapshot.queryParamMap.get("oobCode");
    this.mode = this.route.snapshot.queryParamMap.get("mode");
    if (!this.actionCode) {
      this.errorMessage = "actionCode code missing";
    }
    if (!this.mode) {
      this.errorMessage = "mode missing";
    }
  }

  handleResetPassword(){
    this.firebaseService.handleResetPassword(this.actionCode, this.authFormGroup.get('newPassword').value);
  }
}
