export const environment = {
  production: true,
  websiteURL: "https://dev1.bimshow.io/",
  loginURL: "https://dev1.bimshow.io/login/",
  users_service: {
    host: "dev1.bimshow.io",
    scheme: "https"
  },
  web_app_link: "https://dev1.bimshow.io/app/",
  firebaseConfig: {
    apiKey: "AIzaSyBVzudmKVGnE3Lmgyuttx2NF7zhG0Q0ShA",
    authDomain: "bimshow-dev1.firebaseapp.com",
    databaseURL: "https://bimshow-dev1.firebaseio.com",
    projectId: "bimshow-dev1",
    storageBucket: "bimshow-dev1.appspot.com",
    messagingSenderId: "337558794368",
    appId: "1:337558794368:web:9829c299c9c46af1f8b665"
  },
  cloundFunctionsHost: "https://europe-west3-bimshow-dev1.cloudfunctions.net",
  // cloundFunctionsHost: "http://127.0.0.1:5001/bimshow-dev1/europe-west3",
  stripe: {
    CHECKOUT_PRICES : {
      VIEWS_ADD_1000: "price_1NredaI3csdPgqX1KHZ0hBiC",
      PROJECT_ADD_1: "price_1Nxj90I3csdPgqX1bZ0fhOem",
      SUBSCRIPTION: "price_1Nrf34I3csdPgqX1WXxcayoZ",
    }
  }
};
