<div class="content">
  <img class="logo" src="assets/images/logobimshow10.png">
  <div
    class="title"
    (click)="goWebsite()">
      <span>BIMSHOW</span>
      <!--<span>BIMSHOW - {{'account' | translate}} </span>-->
  </div>
  <div class="spacer"></div>
  <button
  *ngIf="isAuth"
  class="link"
  (click)="goToApp()">
    {{'goToApp' | translate}}
</button>
  <div class="lang">
    <button
      (click)="translate.use('en')">
      <img src="assets/images/gb.svg" />
    </button>
    <button
      (click)="translate.use('fr')">
        <img src="assets/images/fr.svg" />
    </button>
  </div>


  <div class="avatar-image"
      *ngIf="isAuth"
      [ngStyle]="{'background-image':'url('+pictureDownloadUrl+')'}"
      [matMenuTriggerFor]="accountSettings">
      <span
          *ngIf="!pictureDownloadUrl">{{initials}}</span>
  </div>

  <mat-menu #accountSettings="matMenu" class="userMenu">
      <button mat-menu-item (click)="onSignOut()">
          {{ 'logout' | translate}}
      </button>
  </mat-menu>

</div>
<div class="hfooter" *ngIf="userTheme != null">
  <img src="assets/images/header_border_corniche.svg"/>
</div>
