<app-loading *ngIf="firebaseService.authCheck"></app-loading>

<ng-container *ngIf="!firebaseService.authCheck">

  <app-header></app-header>
  <div class="main">

    <ng-container>
      <app-menu *ngIf="isAuth && router.url != '/signin'"></app-menu>
      <div class="page">
        <router-outlet></router-outlet>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <div class="ffooter" *ngIf="userTheme != null">
      <img id="img_footer" src="assets/images/header_border_corniche_footer.svg" />
    </div>
  </div>

</ng-container>